
import { Component, Vue, Watch } from "vue-property-decorator";
import { merchantStore } from "@/store/modules/merchant";
import { cartStore } from "@/store/modules/cart";
import { menuStore } from "@/store/modules/menu";
import { userStore } from "@/store/modules/user";
import IStore from "@/store/models/store";
import { Search } from "@/components";
// import Cookies from "js-cookie";
import cookies from "@/utils/cookies";
import { isNestle } from "@/constant/platform";

@Component({
  name: "StoreList",
  components: {
    Search
  }
})
export default class StoreList extends Vue {
  private storeList: IStore[] = merchantStore.stores;
  private storeResultList: IStore[] = merchantStore.stores;

  protected storeNameQuery = "";
  protected loading = { storeList: true, fetchPosition: true };

  get isNestle() {
    return isNestle();
  }
  get merchant() {
    return merchantStore.merchant;
  }
  get currentStore() {
    return merchantStore.currentStore;
  }
  get stores() {
    return merchantStore.stores;
  }
  get storeResultListLength() {
    return this.storeResultList?.length;
  }
  get merchantSubdomain() {
    const subdomain = window.location.host.split(".")[0];
    if (subdomain.indexOf("192") > -1 || subdomain.indexOf("localhost") > -1) {
      return "test-demo";
    } else if (window.location.host.indexOf("ap.ngrok.io") > -1) {
      return "test-web";
    }
    return subdomain;
  }

  async onSelectStore(id: string) {
    merchantStore.selectStore(null);
    menuStore.loadMenu([]);
    cartStore.clearCart();
    this.$router.push({ name: "StoreView", params: { id: id } });
    cookies.set("currentStore", id);

    const cachedCart = JSON.parse((await cookies.get(`cart-${id}`)) || "null");
    if (cachedCart) {
      cartStore.loadCart(cachedCart);
    }
  }

  get getCurrentPositionPromise(): Promise<Position> {
    return new Promise((resolve, reject) =>
      navigator.geolocation.watchPosition(resolve, reject, {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 60000
      })
    );
  }

  getCurrentPosition(): Promise<{ latitude: number; longitude: number }> {
    return this.getCurrentPositionPromise
      .then((position) => {
        const { latitude, longitude } = position.coords;
        return {
          latitude,
          longitude
        };
      })
      .catch(async () => {
        return fetch("https://geoip.revenuemonster.my/json/")
          .then((resp) => resp.json())
          .then((resp) => {
            return {
              latitude: resp.latitude,
              longitude: resp.longitude
            };
          })
          .catch(() => {
            return {
              //in case of failure, use Kuala Lumpur's coordiantes
              latitude: 3.139003,
              longitude: 101.686855
            };
          });
      });
  }

  async created() {
    if (merchantStore.stores?.length === 0) {
      await merchantStore
        .fetchStores({
          subdomain: this.merchantSubdomain
        })
        .finally(() => {
          merchantStore.clearStore();
        });
      this.loading.storeList = false;
      if (merchantStore.stores?.length > 0) {
        this.storeList = merchantStore.stores || [];

        const { latitude, longitude } = await this.getCurrentPosition();
        const storesWithDistance: any = await userStore.fetchStoresDistance({
          lat: +latitude,
          lng: +longitude
        });

        if (storesWithDistance && storesWithDistance.items) {
          this.storeList.forEach((each) => {
            const storeDistanceReponse =
              storesWithDistance.items.find((x) => x.id === each.id).distance /
              1000;
            each.distance =
              storeDistanceReponse >= 0 ? storeDistanceReponse.toString() : "";
          });
        }

        this.storeList = this.storeList.sort((x, y) => {
          if (parseInt(x.distance) > parseInt(y.distance)) return 1;
          if (parseInt(y.distance) > parseInt(x.distance)) return -1;
          return 0;
        });

        this.loading.fetchPosition = false;
      }
    } else {
      this.loading.storeList = false;
      this.loading.fetchPosition = false;
    }
  }

  @Watch("storeNameQuery")
  onStoreNameQueryChanged(newVal) {
    if (this.storeList.length > 0) {
      const index = newVal.toLowerCase();

      this.storeResultList = this.storeList.filter((each) => {
        return (
          each.name.toLowerCase().includes(index) ||
          each.addressLine1.toLowerCase().includes(index) ||
          each.addressLine2.toLowerCase().includes(index) ||
          each.city.toLowerCase().includes(index) ||
          each.state.toLowerCase().includes(index)
        );
      });
    }
  }

  @Watch("storeList", { immediate: true })
  onStoreListChanged(newVal) {
    this.storeResultList = newVal;
    if (this.$route.name == "StoreList") {
      //avoid duplicate navigation when store distance is fetched
      if (newVal?.length === 1) {
        const ac = this.$route.query?.ac;
        this.$router.replace(`/${newVal[0].id}/home${ac ? "?ac=" + ac : ""}`);
      }
    }
  }
}
