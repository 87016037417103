
import { Component, Vue } from "vue-property-decorator";

import { foodStore } from "@/store/modules/food";
import { merchantStore } from "@/store/modules/merchant";

@Component
export default class FoodTags extends Vue {
  get quickMenu() {
    return foodStore.foodTags;
  }

  public activated() {
    foodStore.getFoodTags();
  }

  get isProduction() {
    return window.location.host.startsWith("web.alacarte.my") || window.location.host.startsWith("alacarte.my");
  }

  onRedirect(m) {
    merchantStore.resetSearchStore();
    this.$router.push({ name: "Search", params: { isReset: "true" }, query: { id: m.id, name: m.name.en } });
  }
}
