import store from "@/store";
import request from "@/utils/request";
import IBanner from "@/store/models/banner";
import qs from "qs";
import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";

export interface IBannerState {
  desktopBanner: IBanner | null;
  tabletBanner: IBanner | null;
  mobileBanner: IBanner | null;
}

@Module({ store, dynamic: true, name: "bannerStore" })
class BannerStore extends VuexModule implements IBannerState {
  desktopBanner: IBanner | null = null;
  tabletBanner: IBanner | null = null;
  mobileBanner: IBanner | null = null;
  banners = {};

  @Mutation
  private SET_BANNERS(banners) {
    this.banners = banners;
  }

  @Action
  public async setBanners(banners) {
    this.SET_BANNERS(banners);
  }

  @Mutation
  private UPDATE_DESKTOP_BANNER(banner: IBanner | null) {
    if (banner) {
      this.desktopBanner = banner;
    }
    console.log("After Update DEsktop Banner", this.desktopBanner)
  }

  @Mutation
  private UPDATE_TABLET_BANNER(banner: IBanner | null) {
    if (banner) {
      this.tabletBanner = banner;
    }
  }

  @Mutation
  private UPDATE_MOBILE_BANNER(banner: IBanner | null) {
    if (banner) {
      this.mobileBanner = banner;
    }
  }

  @Action
  public async fetchBanner(payload) {
    try {
      const { item } = (await request.get(`v3/web/banner?${qs.stringify(payload)}`)) as { item: any };

      switch (payload.target) {
        case "DESKTOP":
          this.UPDATE_DESKTOP_BANNER(item);
          break;
        case "TABLET":
          this.UPDATE_TABLET_BANNER(item);
          break;
        case "MOBILE":
          this.UPDATE_MOBILE_BANNER(item);
          break;
      }

      return item;
    } catch (e) {
      console.error(e);
    }
  }

  @Action
  public async fetchAllBanners() {
    await this.fetchBanner({ target: "DESKTOP" });
    await this.fetchBanner({ target: "TABLET" });
    await this.fetchBanner({ target: "MOBILE" });
  }
}
export const bannerStore = getModule(BannerStore);
