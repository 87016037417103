
import empty from "is-empty";
import { Component, Watch } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { mixin as clickaway } from "vue-clickaway";
import FoodTags from "@/components/Food/FoodTags.vue";
import { merchantStore } from "@/store/modules/merchant";
import { bannerStore } from "@/store/modules/banner";
import { IBannerSlide } from "@/store/models/banner";
import { userStore } from "@/store/modules/user";
import { menuStore } from "@/store/modules/menu";
import { IAddress } from "@/store/models/user";
import MerchantInfo from "@/components/DataDisplay/MerchantInfo.vue";
import SearchBar from "@/components/General/SearchBar.vue";
import { uiStore } from "@/store/modules/ui";
import { Carousel, Slide } from "vue-carousel";
import { getSimpleLoader } from "@/components/General/DynamicImages.vue";
import { foodStore } from "@/store/modules/food";
import cookies from "@/utils/cookies";
import CartModal from "@/components/Custom/CartModal.vue";
import request from "@/utils/request";
import { isBoost } from "@/constant/platform";
import { getEmptyFoodPath } from "@/components/General/DynamicImages.vue";
import "swiper/swiper.scss";
import { Picture } from "@/components";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);

import { Swiper as SwiperClass, Mousewheel } from "swiper/js/swiper.esm";
import getAwesomeSwiper from "vue-awesome-swiper/dist/exporter";
// import 'swiper/swiper-bundle.min.css'
SwiperClass.use([Mousewheel]);
const { Swiper, SwiperSlide } = getAwesomeSwiper(SwiperClass);
import VueBottomSheet from "@webzlodimir/vue-bottom-sheet";

import getDistance from "@/utils/distance";

@Component({
  components: {
    FoodTags,
    MerchantInfo,
    SearchBar,
    Carousel,
    Slide,
    Swiper,
    SwiperSlide,
    Picture,
    VueBottomSheet
  }
})
export default class Home extends mixins(clickaway) {
  private searchFocus = false;
  isLoading = false;
  private isPromptSpinAndWin = false;
  slideOptions = {
    rewind: true,
    perPage: 1,
    autoplay: true,
    pauseOnHover: false,
    arrows: false,
    updateOnMove: true
  };
  hideBanner = false;

  deliveryLocation = {} as any;
  cart = {} as any;
  // tags = {} as any;
  sections = [] as any;
  // banners = {} as any;
  isBannerImageLoaded = false;

  addressConfirmationTooltipsVisible = false;
  chooseAddrTooltipsVisible = false;
  preciseAddrTooltipsVisible = false;
  isDeliveryLocationWatcherBlocked = false;

  defaultSlides = [
    // {
    //   type: "NONE",
    //   imageUrl: "https://asset.alacarte.my/assets/img/banner-en-3.png"
    // }
  ] as IBannerSlide[];

  swiperOptions = {
    direction: "horizontal",
    slidesPerView: 2.5,
    mousewheel: {
      releaseOnEdges: true
    },
    grabCursor: true,
    touchReleaseOnEdges: true,
    // slidesPerGroup: 3
    breakpoints: {
      // when window width is >= 480px
      480: {
        slidesPerView: 3.5
      },
      // when window width is >= 640px
      640: {
        slidesPerView: 3.5
      },
      1024: {
        slidesPerView: 4.5
      }
    }
  };

  showStoreRating(store) {
    return store?.rating?.totalRatings >= 10;
  }

  storeRating(store) {
    const totalStars = store?.rating?.totalRatings * 5;
    const earnedStars = store?.rating?.stars;
    return ((earnedStars / totalStars) * 5).toFixed(2);
  }

  totalRatingCount(store) {
    return store?.rating?.totalRatings;
  }

  get getEmptyFoodPath() {
    return getEmptyFoodPath();
  }

  get banners() {
    return bannerStore.banners;
  }

  get loader() {
    return getSimpleLoader().simpleLoader;
  }

  get loaderWebP() {
    return getSimpleLoader().simpleLoaderWebP;
  }

  get stores() {
    if (this.isProduction) {
      return merchantStore.allStores.filter(
        (each) => each.merchant.merchantId !== "4118165203679668885"
      );
    }
    return merchantStore.allStores;
  }
  get foodTags() {
    return foodStore.foodTags || {};
  }
  get featuredStores() {
    return merchantStore.featuredStores;
  }
  get storesCursor() {
    return merchantStore.storesCursor;
  }
  get isEndOfList() {
    return (
      !this.isLoading &&
      !this.fetchingStores &&
      this.stores.length > 0 &&
      !this.storesCursor
    );
  }

  get fetchingStores() {
    return merchantStore.fetchingStores;
  }

  get isLoadMoreStoresDisabled() {
    return this.fetchingStores || !this.storesCursor;
  }

  get desktopBanners() {
    return bannerStore.desktopBanner?.slides;
  }

  get tabletBanners() {
    return bannerStore.tabletBanner?.slides;
  }

  get mobileBanners() {
    return bannerStore.mobileBanner?.slides;
  }

  get currentBanners() {
    var banners: IBannerSlide[] | null | undefined = null;
    if (this.isDesktop) {
      banners = this.desktopBanners;
    } else if (this.isTablet) {
      banners = this.tabletBanners;
    } else if (this.isMobile) {
      banners = this.mobileBanners;
    }

    if (!banners || (Array.isArray(banners) && banners.length == 0)) {
      banners = this.defaultSlides;
    }

    return banners;
  }

  get platform() {
    return merchantStore.platform?.toLowerCase();
  }
  get BASE_URL() {
    return process.env.BASE_URL;
  }

  openDeliveryAddressBottomSheet() {
    const bottomSheet: any = this.$refs.deliveryAddressBottomSheet;
    bottomSheet.open();
  }

  closeDeliveryAddressBottomSheet() {
    const bottomSheet: any = this.$refs.deliveryAddressBottomSheet;
    cookies.setSessionStorage(
      "showDeliveryAddressBottomSheet",
      JSON.stringify(false)
    );
    bottomSheet.close();
  }
  hideAddressConfirmationTooltips() {
    this.addressConfirmationTooltipsVisible = false;
    cookies.setSessionStorage(
      "showAddressConfirmationTooltips",
      JSON.stringify(false)
    );
  }
  hideChooseAddrTooltips() {
    this.chooseAddrTooltipsVisible = false;
    cookies.setSessionStorage("showChooseAddrTooltips", JSON.stringify(false));
  }

  hidePreciseAddrTooltips() {
    this.preciseAddrTooltipsVisible = false;
    cookies.setSessionStorage("showPreciseAddrTooltips", JSON.stringify(false));
  }

  allowLocationsAccess() {
    this.$ionic.alertController
      .create({
        header: "Locations",
        // subHeader: "Subtitle",
        message: "Go to site settings, and enable the location permission.",
        buttons: ["OK"]
      })
      .then((alert) => {
        alert.present();
      });
    this.closeDeliveryAddressBottomSheet();
  }

  async onViewCart() {
    this.$ionic.modalController
      .create({
        component: CartModal as any,
        componentProps: {
          data: {},
          propsData: {
            title: this.$t("my-cart"),
            $router: this.$router,
            $t: this.$t,
            self: this,
            cart: this.cart
          }
        }
      })
      .then((m) => {
        m.present();
        m.onDidDismiss().then(() => this.updateCart());
      });
  }

  async updateCart() {
    this.cart = await Object.keys(localStorage)
      .filter((key) => key.startsWith("cart-"))
      .reduce((acc, k) => {
        const key = k.replace("cart-", "");
        const items = JSON.parse(localStorage.getItem(k) || "[]");
        if (items?.length > 0) {
          acc[key] = {
            id: key,
            cart: items
          };
        }
        return acc;
      }, {});
  }

  getItemPrice(amount: number, rate: number) {
    return menuStore.toFormattedTaxInclusive(amount, rate);
  }

  formatDistance(distance) {
    if (+distance > 1000) {
      return `${(+distance / 1000).toFixed(1)} km`;
    } else {
      return `${+distance} m`;
    }
  }

  onRedirect(m) {
    merchantStore.resetSearchStore();
    this.$router.push({
      name: "Search",
      params: { isReset: "true", tag: m },
      query: {
        id: m.id,
        name: m.name[this.$i18n.locale],
        imageUrl: m.imageUrl,
        bannerUrl: m.bannerUrl ? m.bannerUrl : "",
        redirectUrl: m.redirectUrl ? m.redirectUrl : ""
      }
    });
  }

  onRedirectBanner(b) {
    if (b.link) {
      window.location.href = b.link;
    }
  }

  loadMoreButtonInView() {
    const position = window.innerHeight + window.scrollY;
    const totalHeigh = document.documentElement?.scrollHeight;

    if (position < totalHeigh + 10 && position > totalHeigh - 10) {
      if (!this.fetchingStores && this.storesCursor) {
        this.loadMoreStores();
      }
    }
  }

  get isProduction() {
    return (
      window.location.host.startsWith("web.alacarte.my") ||
      window.location.host.startsWith("alacarte.my")
    );
  }

  get isBoost() {
    return isBoost();
  }

  get user() {
    return userStore.user;
  }

  protected clickAway() {
    this.searchFocus = false;
    this.hideAddressConfirmationTooltips();
  }

  public async loadMoreStores() {
    this.isLoading = true;
    // const { latitude, longitude } = await userStore.getCurrentPosition();

    const queryAddress = {
      lat: this.deliveryLocation.lat,
      lng: this.deliveryLocation.lng
      // lat: 3.133257,
      // lng: 101.615497
    } as IAddress;
    const params = {
      searchInput: "",
      address: queryAddress,
      rcursor: merchantStore.storesCursor,
      isSearch: false,
      isAppendSearch: false,
      foodTagId: ""
    };
    await merchantStore.getStores(params);
    this.isLoading = false;
    uiStore.setLoader(false);
  }

  public onClickSlide(slide: IBannerSlide) {
    if (slide.link != "") {
      if (slide.type == "WEB" || slide.type == "DEEPLINK") {
        window.open(slide.link, "_blank");
      }
    }
  }

  protected async fetchSpinAndWinChances() {
    const noOfChances = (await userStore.fetchGameChances()) as Number;
    if (noOfChances > 0) {
      this.isPromptSpinAndWin = true;
    }
  }

  get isDesktop() {
    return window.outerWidth >= 1024;
  }

  get isTablet() {
    return window.outerWidth >= 768 && window.outerWidth < 1024;
  }

  get isMobile() {
    return !this.isTablet && !this.isDesktop;
  }

  privacyUpdateClicked(e) {
    cookies.set("hidePrivacyUpdate", true);
    this.hideBanner = true;
    e.stopPropagation();
  }

  toPrivacyPage(e) {
    let routeData = this.$router.resolve({ name: "Privacy" });
    window.open(routeData.href, "_blank");
    // this.$router.push({ name: "Privacy" });
    cookies.set("hidePrivacyUpdate", true);
    this.hideBanner = true;
    e.stopPropagation();
  }

  @Watch("$route", { immediate: true })
  async onRouteChanged() {
    const dL = await cookies.get("delivery:location");

    if (
      dL != (await cookies.get("home:location")) ||
      empty(this.deliveryLocation)
    ) {
      try {
        this.deliveryLocation = JSON.parse(dL || "{}");
        cookies.set("home:location", dL || "{}");
      } catch (e) {
        console.error(e);
      }
    }
    this.updateCart();
  }

  fetchHorizontalCard(payload) {
    let query = "";
    if (payload?.latitude && payload?.longitude) {
      query = new URLSearchParams(payload).toString();
    }
    request
      .get(`v3/web/tags?${query}`)
      .then(({ items = { foodTags: [] } }: any) => {
        // bannerStore.setBanners(
        //   items.banners.reduce((acc, banner) => {
        //     return { ...acc, [banner.target]: banner };
        //   }, {})
        // );
        foodStore.setFoodTags(
          items.foodTags.reduce((acc, tag) => {
            return { ...acc, [tag.id]: tag };
          }, {})
        );
        this.sections = items.horizontalCard;
        // sort sections by open => preorder (closed shop wont be returned from backend)
        this.sections = this.sections.map((section, i) => {
          const { store, ...otherProps } = section;
          let openStore = [];
          let preorderStore = [];
          openStore = store.filter((s) => s.isOpen);
          preorderStore = store.filter((s) => !s.isOpen);
          const totalStore = [...openStore, ...preorderStore];
          return {
            ...otherProps,
            store: totalStore
          };
        });
      });
  }

  showChooseAddrTooltips() {
    this.chooseAddrTooltipsVisible = true;
    setTimeout(() => {
      this.hideChooseAddrTooltips();
    }, 1000 * 10);
  }

  handleSlideClick(value) {
    if (value.link) {
      window.location.href = value.link;
    }
    console.log(value.link);
  }

  get isFoodStore() {
    return (tag: any) => {
      return merchantStore.foodBusinessCategories.includes(
        tag.businessCategory
      );
    };
  }

  filterAddress(result, column) {
    const x = result.address_components.filter((each) =>
      each.types.includes(column)
    );
    if (x.length > 0) {
      return x[0].long_name.trim();
    }
    return "";
  }

  @Watch("user")
  onUserChanged() {
    if (this.user?.isBinded) {
      this.fetchSpinAndWinChances();
    }
  }

  @Watch("deliveryLocation", { deep: true })
  async onLocationChanged() {
    if (this.isDeliveryLocationWatcherBlocked) return;
    const payload = {};
    this.sections = [];
    // { latitude: 3.133257, longitude: 101.615497 } testing location

    merchantStore.resetStores();

    if (this.deliveryLocation.lat && this.deliveryLocation.lng) {
      Object.assign(payload, {
        latitude: this.deliveryLocation.lat,
        longitude: this.deliveryLocation.lng
      });
      // this.isLoading = true;
      this.fetchHorizontalCard(payload);
      if (this.stores.length === 0) {
        this.loadMoreStores();
      }
      navigator.geolocation.getCurrentPosition(
        async ({ coords }) => {
          const distance = getDistance(
            coords.latitude,
            coords.longitude,
            this.deliveryLocation.lat,
            this.deliveryLocation.lng,
            "K"
          );
          if (
            !this.deliveryLocation.unit &&
            !this.deliveryLocation.street_number
          ) {
            this.preciseAddrTooltipsVisible = JSON.parse(
              (await cookies.getSessionStorage("showPreciseAddrTooltips")) ||
                "true"
            );
            setTimeout(() => {
              this.hidePreciseAddrTooltips();
            }, 1000 * 10);
          } else if (distance >= 5) {
            this.addressConfirmationTooltipsVisible = JSON.parse(
              (await cookies.getSessionStorage(
                "showAddressConfirmationTooltips"
              )) || "true"
            );
            setTimeout(() => {
              this.hideAddressConfirmationTooltips();
            }, 1000 * 10);
          }
        },
        async () => {
          const showDeliveryAddressBottomSheet = JSON.parse(
            (await cookies.getSessionStorage(
              "showDeliveryAddressBottomSheet"
            )) || "true"
          );
          if (showDeliveryAddressBottomSheet) {
            this.openDeliveryAddressBottomSheet();
          }
        },
        { maximumAge: 60000 }
      );
    } else {
      this.isLoading = true;
      navigator.geolocation.getCurrentPosition(
        ({ coords }) => {
          Object.assign(payload, {
            latitude: coords.latitude,
            longitude: coords.longitude
          });
          this.fetchHorizontalCard(payload);
          if (this.stores.length === 0) {
            this.loadMoreStores();
          }

          const geocoder = new google.maps.Geocoder();
          const latlng = { lat: coords.latitude, lng: coords.longitude };

          geocoder
            .geocode({
              location: latlng
              // componentRestrictions: {
              //   country: "MY",
              // }
            })
            .then((response) => {
              // const trimmedRes = this.removePlusCode(response.results);
              // console.log("resp", response, trimmedRes)
              if (response.results?.length) {
                const place = response.results[0];

                const route = this.filterAddress(place, "route");
                const addressLine2 = "";
                const street_number = this.filterAddress(
                  place,
                  "street_number"
                );
                let unit = this.filterAddress(place, "street_number");

                const postcode = this.filterAddress(place, "postal_code");
                const city = this.filterAddress(place, "locality");
                const state = this.filterAddress(
                  place,
                  "administrative_area_level_1"
                )
                  .replace("Negeri", "")
                  .replace("Wilayah Persekutuan", "")
                  .trim();
                const country = this.filterAddress(place, "country");
                // const pluscode = this.filterAddress(place, "plus_code");
                let formattedAddress = place.formatted_address;
                let addressLine1 = formattedAddress
                  .split(", ")
                  .map((each) => {
                    return each
                      .trim()
                      .replace("Federal Territory of", "")
                      .replace(/, /g, "")
                      .trim();
                  })
                  .filter((each) => {
                    let valid =
                      (postcode.length > 0 ? !each.includes(postcode) : true) &&
                      (city.length > 0 ? !each.includes(city) : true) &&
                      (state.length > 0 ? !each.includes(state) : true) &&
                      (country.length > 0 ? !each.includes(country) : true);
                    return valid;
                  });

                if (addressLine1.length == 0 && route.length > 0) {
                  //sometimes the street name contains the city name, and it won't pass the previous filter
                  addressLine1.push(route);
                }
                // else if (addressLine1.length == 0 && pluscode.length > 0) {
                //   // handle pluscode addresses
                //   addressLine1.push(pluscode);
                // }

                if (addressLine1.length > 0) {
                  let addrL1 = addressLine1.join(", ");
                  //any place name that's higher than route level can be added in the beginning
                  // if (!addrL1.includes(place.name)) addrL1 = place.name + " " + addrL1;
                  const addr = {
                    addressLine1: addrL1,
                    addressLine2,
                    postcode,
                    city,
                    state,
                    country,
                    unit,
                    lat: place.geometry.location.lat(),
                    lng: place.geometry.location.lng(),
                    street_number
                  };
                  const deliveryLocation = {
                    ...addr,
                    ...latlng
                  };
                  this.blockDeliveryLocationWatcher();
                  cookies.set(
                    "delivery:location",
                    JSON.stringify(deliveryLocation)
                  );

                  this.deliveryLocation = deliveryLocation;
                } else {
                  this.showChooseAddrTooltips();
                }
              } else {
                this.showChooseAddrTooltips();
              }
            })
            .catch((e) => {
              this.showChooseAddrTooltips();
            });
        },
        (e) => {
          this.showChooseAddrTooltips();
          this.fetchHorizontalCard(payload);
          if (this.stores.length === 0) {
            this.loadMoreStores();
          }
        },
        { maximumAge: 60000 }
      );
    }
  }

  blockDeliveryLocationWatcher() {
    this.isDeliveryLocationWatcherBlocked = true;
    setTimeout(() => {
      this.isDeliveryLocationWatcherBlocked = false;
    }, 1000);
  }

  created() {
    request.get(`v4/web/tags`).then(({ items = { banners: [] } }: any) => {
      bannerStore.setBanners(
        items.banners.reduce((acc, banner) => {
          return { ...acc, [banner.target]: banner };
        }, {})
      );
      foodStore.setFoodTags(
        items.foodTags.reduce((acc, tag) => {
          return { ...acc, [tag.id]: tag };
        }, {})
      );
    });
  }

  async activated() {
    if (this.user?.isBinded) {
      this.fetchSpinAndWinChances();
    }
    window.addEventListener("scroll", this.loadMoreButtonInView);
  }
  deactivated() {
    window.removeEventListener("scroll", this.loadMoreButtonInView);
  }
}
